<template>
  <div v-if="pageLoaded">
    <div v-if="showWelcomePage">
      <welcome-page @goto-submissionSections="gotoSections"> </welcome-page>
    </div>
    <div v-if="shouldShowThankyouPage">
      <thankyou-page> </thankyou-page>
    </div>
    <div v-if="!showWelcomePage && !shouldShowThankyouPage">
      <v-app-bar color="primary" dark>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-show="renderingType === 'section'"
        ></v-app-bar-nav-icon>
        <v-toolbar-title>{{ selectedSubmission.title }}</v-toolbar-title>
      </v-app-bar>

      <v-navigation-drawer app clipped mobile-breakpoint="576" v-model="drawer">
        <v-list nav dense>
          <v-subheader class="text-uppercase">Sections</v-subheader>
          <v-list-item-group
            mandatory
            class="px-3 pt-5"
            v-model="activeSubmissionSection"
          >
            <template v-for="section in selectedSubmission.sections">
              <v-list-item
                v-if="section.type === 'normal' || section.type === 'summary'"
                :key="section.id"
                link
                class="px-2 py-3"
                dense
                @click="changeSection(section)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ section.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-container>
        <forms-engine-component
          v-if="selectedSubmissionSection.type === 'normal'"
          ref="formEngine"
          :form-id="formId"
          :rendering-type="renderingType"
          :section-id="selectedSubmissionSection.id"
          :client-token="clientToken"
          :submission-token="submissionToken"
          :api-url="apiUrl"
          basic-datepick="false"
          is-guest="true"
          :pubnub-subscribe-key="subscribekey"
          :pubnub-publish-key="publishkey"
          :pubnub-uuid="pubnubuuid"
          @requestingServer="e => this.showSpinner(e)"
          @formMessage="e => this.showFormMessage(e)"
          @formEngineLoaded="e => this.setFormEngineStatus(e)"
        >
        </forms-engine-component>
        <layout-summary
          v-else-if="selectedSubmissionSection.type === 'summary'"
          :selectedSection="selectedSubmissionSection"
          :formId="formId"
          :key="selectedSubmissionSection.id"
          :refAnwers="refAnwers"
          ref="layoutSummary"
          @show-thankyou-page="showThankyouPage"
        />
        <v-layout
          justify-space-between
          v-if="isFormEngineLoaded && renderingType === 'section'"
          class="mt-2"
        >
          <v-btn
            color=""
            :disabled="isFirstSection"
            depressed
            @click="previousSection"
            >Previous</v-btn
          >
          <v-btn v-if="isLastSection" color="" depressed @click="submit"
            >Submit</v-btn
          >
          <v-btn v-else depressed @click="nextSection">Next</v-btn>
        </v-layout>
        <v-layout
          justify-space-between
          v-if="isFormEngineLoaded && renderingType === 'question'"
          class="mt-2"
        >
          <v-btn color="" depressed @click="previousQuestion">Previous</v-btn>
          <v-btn depressed @click="nextQuestion">Next</v-btn>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import WelcomePage from '@/components/settings/Welcome'
import ThankyouPage from '@/components/settings/Thankyou'
import LayoutSummary from '@/components/form/formLayout/LayoutSummary'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import uiHelper from '@/helpers/ui'

export default {
  name: 'FormPublic',

  components: {
    WelcomePage,
    ThankyouPage,
    LayoutSummary
  },

  data() {
    return {
      drawer: true,
      showAssigned: false,
      holdSubmissionModal: false,
      success: false,
      submissionOnHoldReasons: [],
      activeSubmissionSection: 0,
      submissionOnHoldReasonsValue: '',
      loading: false,
      isSubmissionOnHoldStatus: false,
      publicFormAvailable: true,
      allSections: [],
      refAnwers: {},
      pageLoaded: false,
      showWelcomePage: false,
      shouldShowThankyouPage: false,
      allFieldsAnswered: false,

      isFormEngineLoaded: false,
      renderingType: 'section',
      selectedQuestion: {}
    }
  },

  computed: {
    ...mapGetters({
      formId: 'form/selectedFormId',
      selectedSubmission: 'form/selectedSubmission',
      selectedSubmissionStats: 'form/selectedSubmissionStats',
      selectedSubmissionSection: 'form/selectedSubmissionSection',
      selectedSubmissionDetails: 'form/selectedSubmissionDetails',
      isSelectedSubmissionOnHold: 'form/isSelectedSubmissionOnHold',
      clientToken: 'user/clientToken',
      submissionToken: 'form/selectedSubmissionToken'
    }),

    businessSlug() {
      return this.$route.params.business_slug
    },

    selectedSectionIndex() {
      return this.selectedSubmission.sections.findIndex(
        item => item.id === this.selectedSubmissionSection.id
      )
    },
    sectionHasRequiredQuestion() {
      let required = this.selectedSubmissionSection.questions.find(
        question => question.is_required === true
      )
      if (required) return true
      else return false
    },

    isFirstSection() {
      if (this.selectedSectionIndex === 0) return true
      return false
    },

    isLastSection() {
      const totalSections = this.selectedSubmission.sections.length
      if (this.selectedSectionIndex + 1 === totalSections) return true
      return false
    },

    isFirstQuestion() {
      const questions = this.selectedSubmissionSection.questions
      if (this.selectedQuestion.id === questions[0].id) {
        return true
      }
      return false
    },

    isLastQuestion() {
      const questions = this.selectedSubmissionSection.questions
      if (this.selectedQuestion.id === questions[questions.length - 1].id) {
        return true
      }
      return false
    },
    apiUrl() {
      return process.env.VUE_APP_FORMS_URL
    },
    subscribekey() {
      return process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY
    },
    publishkey() {
      return process.env.VUE_APP_PUBNUB_PUBLISH_KEY
    },
    pubnubuuid() {
      return process.env.VUE_APP_PUBNUB_UUID
    }
  },

  beforeCreate() {
    localStorage.clear()
  },

  async created() {
    await this.setupPublicForm()
    if (this.renderingType === 'question') {
      this.drawer = false
      this.selectedQuestion = this.selectedSubmissionSection.questions[0]
    } else {
      this.drawer = true
    }
    this.setSummarySection()
    this.pageLoaded = true
  },

  destroyed() {
    // TODO: Clear form/store
    this.resetSelectedForm()
  },

  methods: {
    ...mapMutations({
      setSelectedSubmissionSection: 'form/setSelectedSubmissionSection',
      resetSelectedForm: 'form/resetSelectedForm',
      setSelectedSubmission: 'form/setSelectedSubmission'
    }),

    ...mapActions({
      createGuestRespondent: 'user/createGuestRespondent',
      startNewFormSubmission: 'form/startNewFormSubmission',
      getSubmissionDetails: 'form/getSubmissionDetails',
      getQuestionsBySection: 'form/getQuestionsBySection',
      getSectionResponses: 'form/getSectionResponses',
      setBranding: 'business/getBusinessDetailsBySlug'
    }),

    setSummarySection() {
      let showSummaryPage = this.selectedSubmission.settings.show_summary_page

      let hasSummarySection =
        this.selectedSubmission.sections[
          this.selectedSubmission.sections.length - 1
        ].type === 'summary'
          ? true
          : false

      if (showSummaryPage) {
        if (hasSummarySection) return
        else {
          let newSubmission = { ...this.selectedSubmission }
          let newSummarySection = {
            form_id: this.selectedSubmissionSection.form_id,
            id: 'custom-summary',
            type: 'summary',
            questions: [],
            title: 'Summary'
          }
          newSubmission.sections.push(newSummarySection)
          this.setSelectedSubmission(newSubmission)
        }
      } else {
        if (!hasSummarySection) return
        else {
          let newSubmission = { ...this.selectedSubmission }
          newSubmission.sections.pop()
          this.setSelectedSubmission(newSubmission)
        }
      }
    },

    async submit() {
      if (this.selectedSubmissionSection.type === 'normal') {
        try {
          let res = await this.$refs.formEngine._submitForm()
          if (res && this.selectedSubmission.settings.show_thankyou_page) {
            this.showThankyouPage()
          }
        } catch (error) {
          throw error
        }
      } else if (this.selectedSubmissionSection.type === 'summary') {
        try {
          await this.$refs.layoutSummary._submitForm()
        } catch (error) {
          throw error
        }
      }
    },

    gotoSections() {
      this.showWelcomePage = false
    },

    async setupPublicForm() {
      uiHelper.progressBarStatus(false)
      let clientToken = null
      let startingNewSubmission = false
      if (sessionStorage.getItem('clientToken')) {
        clientToken = sessionStorage.getItem('clientToken')
      }
      try {
        await this.setBranding(this.businessSlug)
        if (!clientToken) {
          startingNewSubmission = true
          await this.createGuestRespondent()
        }
        await this.startNewFormSubmission({ formId: this.$route.params.id })

        await this.getQuestionsBySection({ isPublicForm: this.$_isPublicForm })
        if (
          startingNewSubmission &&
          this.selectedSubmission.settings.show_welcome_page
        ) {
          this.showWelcomePage = true
        }
      } catch (error) {
        if (error.respones?.data?.message) {
          uiHelper.showSnackbarMessage(error.respones?.data?.message, 'error')
        }
        throw error
      }

      uiHelper.progressBarStatus(false)
    },

    async changeSection(section) {
      uiHelper.progressBarStatus(true)

      this.setSelectedSubmissionSection(section)
      this.changeSubmissionSectionActiveClass(this.selectedSectionIndex)
      uiHelper.progressBarStatus(false)
    },

    changeSubmissionSectionActiveClass(selectedSectionIndex) {
      this.activeSubmissionSection = selectedSectionIndex
    },

    showThankyouPage() {
      this.shouldShowThankyouPage = true
    },

    isQuestionRequired(queID) {
      const question = this.selectedSubmissionSection.questions.find(
        i => i.id.toString() === queID
      )
      if (!question) return false
      return question.is_required
    },

    showSpinner(e) {
      if (e.detail) uiHelper.progressBarStatus(true)
      else uiHelper.progressBarStatus(false)
    },

    showFormMessage(e) {
      uiHelper.showSnackbarMessage(e.detail.message, e.detail.type)
    },

    setFormEngineStatus(e) {
      this.isFormEngineLoaded = e.detail
    },

    previousSection() {
      const nextSection = this.selectedSubmission.sections[
        this.selectedSectionIndex - 1
      ]
      this.changeSection(nextSection)
    },

    async nextSection() {
      if (await this.$refs.formEngine._validate()) {
        const nextSection = this.selectedSubmission.sections[
          this.selectedSectionIndex + 1
        ]
        this.changeSection(nextSection)
      } else {
        uiHelper.showSnackbarMessage('Please fill required fields', 'error')
      }
    },

    previousQuestion() {
      this.$refs.formEngine._previousQuestion()
    },

    nextQuestion() {
      this.$refs.formEngine._nextQuestion()
    }
  }
}
</script>
