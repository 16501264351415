<template>
  <div>
    <div v-if="summaryBound">
      <div v-if="showSummary">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(answer, key, i) in selectedSubmissionSummary.answers"
            :key="i"
          >
            <v-expansion-panel-header>
              {{
                `${selectedSubmissionSummary.answers[key].length} answers were answerd: ${key}`
              }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">No.</th>
                    <th class="text-left">Section</th>
                    <th class="text-left">Question</th>
                    <th class="text-left">Answer</th>
                    <th class="text-left">Answered By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index1) in selectedSubmissionSummary.answers[
                      key
                    ]"
                    :key="index1"
                  >
                    <td>{{ index1 + 1 }}</td>
                    <td>{{ item.section }}</td>
                    <td>{{ item.question }}</td>
                    <td>{{ item.answer }}</td>
                    <td>{{ item.answered_by }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div v-else>
        <p class="text-center">
          Summary unavailable. Please select some answers and try again.
        </p>
      </div>

      <div class="mt-5" v-if="summaryBound && summaryImagesLength">
        <div class="title">Photos of Vehicle</div>
        <v-row>
          <v-col
            v-for="(item, index) in attachments"
            cols="12"
            sm="3"
            :key="index"
          >
            <v-img
              contain
              class
              height="300px"
              color="grey"
              :src="item.image_url"
              alt="Vehicle Thumbnail"
              @click="openShowImageDialog(item)"
            ></v-img>
          </v-col>
          <!-- <v-col
            cols="12"
            sm="3"
            v-for="(item, index) in selectedSubmissionSummary.image_notes"
            :key="index"
          >
            <template v-for="(side, j) in item">
              <div :key="j">
                <div v-for="(image_url, k) in side.attachments" :key="k">

                  <v-img
                    contain
                    class
                    height="300px"
                    color="grey"
                    :class="{
                      'selected-img':
                        item.attachment === selectedImageNote.attachment
                    }"
                    :src="image_url"
                    alt="Vehicle Thumbnail"
                  ></v-img>
                </div>
              </div>
            </template>
          </v-col>-->
        </v-row>
      </div>

      <!-- <div
        class="mt-5"
        v-if="summaryBound && summaryImagesLength && !$_isPublicForm"
      >
        <div class="field-label is-normal">
          <label class="title">Notes:</label>
        </div>
        <div class="mt-3">
          <div class="field">
            <div class="control">
              <v-textarea outlined v-model="selectedImageNote.notes" rows="6" />
            </div>
          </div>
        </div>
      </div>-->

      <div
        class="notification is-primary mt-6"
        v-if="isSubmissionSubmitted === true"
      >
        <strong style="font-size: 20px; font-family: Arial">
          <div v-if="!permissions.app_permissions.submissions.approve">
            Form is already completed.
          </div>
          <div
            v-else-if="
              permissions.app_permissions.submissions.approve &&
                selectedSubmission.submission.status.toLowerCase() ===
                  'approved'
            "
          >
            Form is already approved.
          </div>
          <div
            v-else-if="
              permissions.app_permissions.submissions.reject &&
                selectedSubmission.submission.status.toLowerCase() ===
                  'rejected'
            "
          >
            <div>Form is already rejected.</div>
            <!-- <div>Reason: {{ selectedSubmissionDetails.rejected_reasons }}</div> -->
          </div>
        </strong>
      </div>

      <!-- SUBMIT MODAL -->
      <v-dialog v-model="submitSubmissionModal" max-width="500px">
        <v-card>
          <v-card-title>Signature Required</v-card-title>
          <v-card-text>
            <!-- <div class="mb-3">
              It is the responsibility of the inspecting dealer that this
              Inspection and Certifications Checklist be compelted in accordance
              with the Volkswagen Cerified pre-owned Program manual.
            </div>-->
            <div>
              <option-signature
                :formId="formId"
                @signature-generated="handleSignature"
                @signature-cleared="clearSignature"
                :question="signatureQuestion || {}"
                :label="signatureQuestion.title || ''"
              />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :loading="buttonProgress"
              :disabled="this.signature.file === ''"
              color="primary"
              @click="submitForm"
              class="ma-3"
              >Complete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showImageDialog" :width="600">
        <v-card flat>
          <v-card-title
            >{{ selectedAttachment.side }}
            <v-spacer> </v-spacer>
            <v-btn icon @click="showImageDialog = false">
              <v-icon> close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-img
              :src="selectedAttachment.image_url"
              alt="image"
              class="my-4"
            ></v-img>
            <div class="title">Notes:</div>
            <div>{{ selectedAttachment.notes }}</div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import OptionSignature from '@/components/options/OptionSignature'
import FormPublicApi from '@/api/formPublic/formPublic'
import { mapGetters, mapActions } from 'vuex'
import uiHelper from '@/helpers/ui'

export default {
  name: 'LayoutSummary',

  components: {
    OptionSignature,
    // eslint-disable-next-line vue/no-unused-components
    OptionDateTime: () => import('@/components/options/OptionDateTime')
  },

  data() {
    return {
      summary: '',
      errors: '',
      selectedImageNote: '',
      summaryBound: false,
      signature: {
        modal: false,
        file: '',
        presignedURL: '',
        url: ''
      },
      manager: {
        rejected_modal: false,
        reason: '',
        approved: true
      },
      selectedImage: '',
      submitSubmissionModal: false,
      rejectSubmissionModal: false,
      content: new Date().toISOString().substring(0, 10),
      menu: false,
      buttonProgress: false,
      showImageDialog: false,
      selectedAttachment: {}
    }
  },

  props: {
    selectedSection: Object,

    formId: {
      type: String,
      required: true
    },
    refAnwers: {
      type: Object
    }
  },
  watch: {
    refAnwers: {
      handler(newValue) {
        if (newValue) {
          let ref = this.selectedSubmissionSection.questions[0].options[0].ref
          if (ref === Object.keys(this.refAnwers)[0]) {
            this.signature.url = this.refAnwers[ref]
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      selectedForm: 'form/selectedForm',
      selectedSubmissionSummary: 'form/selectedSubmissionSummary',
      isManager: 'user/isManager',
      permissions: 'user/userPermissions',
      selectedSubmissionDetails: 'form/selectedSubmissionDetails',
      selectedSubmission: 'form/selectedSubmission',
      selectedSubmissionSection: 'form/selectedSubmissionSection'
    }),

    isSubmissionSubmitted() {
      if (this.selectedSubmission.hasOwnProperty('submission')) {
        if (
          this.selectedSubmission.submission.status.toLowerCase() ===
            'submitted' ||
          this.selectedSubmission.submission.status.toLowerCase() ===
            'approved' ||
          this.selectedSubmission.submission.status.toLowerCase() === 'rejected'
        ) {
          return true
        }
      }
      return false
    },

    isSubmissionApproved() {
      if (
        this.selectedSubmission.submission.status.toLowerCase() === 'approved'
      ) {
        return true
      }
      return false
    },

    summaryImagesLength() {
      if (this.selectedSubmissionSummary) {
        let length = 0
        for (const i in this.selectedSubmissionSummary.image_notes) {
          for (let j of this.selectedSubmissionSummary.image_notes[i]) {
            length = length + j.attachments.length
          }
        }
        return length
      }
      return false
    },

    showSummary() {
      const summary = this.selectedSubmissionSummary
      const flag =
        summary &&
        summary !== '' &&
        summary !== undefined &&
        (Object.keys(summary.answers).length > 0 ||
          summary.image_notes.length > 0)
      return flag
    },
    signatureQuestion() {
      let question = this.selectedSubmissionSection.questions.find(question =>
        question.options.some(option => option.type === 'signature')
      )
      if (question) return question
      return {}
    },

    rejectingReasons() {
      let reasons = []
      if (this.selectedForm.hasOwnProperty('attributes')) {
        if (this.selectedForm.attributes.hasOwnProperty('rejecting_reasons')) {
          reasons = this.selectedForm.attributes.rejecting_reasons
        }
      }
      return reasons
    },

    approvalReasons() {
      let reasons = []
      if (this.selectedForm.hasOwnProperty('attributes')) {
        if (this.selectedForm.attributes.hasOwnProperty('approval_reasons')) {
          reasons = this.selectedForm.attributes.approval_reasons
        }
      }
      return reasons
    },

    attachments() {
      let attachments = []
      for (const side in this.selectedSubmissionSummary.image_notes) {
        this.selectedSubmissionSummary.image_notes[side].forEach(point => {
          point.attachments.forEach(image => {
            attachments = [
              ...attachments,
              {
                side: side,
                image_url: image,
                notes: point.notes
              }
            ]
          })
        })
      }
      return attachments
    },

    isSignatureRequired() {
      let required = false
      let questions = [...this.selectedSubmissionSection.questions]
      questions.forEach(question => {
        let signature = question.options.find(
          option => option.type === 'signature'
        )
        if (signature) required = true
      })
      return required
    }
  },

  async created() {
    await this.getSummary()
  },

  mounted() {
    if (!this.selectedSubmissionSection.id !== 'custom-summary') {
      this.getQuestionsBySection()
    }
  },

  methods: {
    ...mapActions({
      getSummaryFromState: 'form/getSummary',
      getQuestionsBySection: 'form/getQuestionsBySection'
    }),

    async _submitForm() {
      if (this.isSignatureRequired) {
        this.submitSubmissionModal = true
      } else {
        await this.submitForm()
      }
    },

    async submitForm() {
      this.buttonProgress = true
      this.errors = ''
      uiHelper.progressBarStatus(true)
      try {
        uiHelper.progressBarStatus(false)

        const response = await FormPublicApi.submitForm({
          form_id: this.formId
        })
        uiHelper.showSnackbarMessage(response.data.msg)
        if (this.selectedSubmission.settings.show_thankyou_page) {
          this.$emit('show-thankyou-page')
        }
      } catch (error) {
        uiHelper.showSnackbarMessage(error.response.data.msg, 'error')
      } finally {
        uiHelper.progressBarStatus(false)
      }
      this.buttonProgress = false
      this.submitSubmissionModal = false
    },

    handleSignature(signature) {
      this.signature.file = signature.file
      this.signature.url = signature.url
      this.signature.presignedURL = signature.presignedURL
    },

    clearSignature() {
      this.signature.file = ''
      this.signature.url = ''
    },

    async getSummary() {
      await this.getSummaryFromState().then(() => {
        this.summaryBound = true
      })
    },

    openShowImageDialog(item) {
      this.selectedAttachment = item
      this.showImageDialog = true
    }
  }
}
</script>
