var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.summaryBound)?_c('div',[(_vm.showSummary)?_c('div',[_c('v-expansion-panels',_vm._l((_vm.selectedSubmissionSummary.answers),function(answer,key,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(((_vm.selectedSubmissionSummary.answers[key].length) + " answers were answerd: " + key))+" ")]),_c('v-expansion-panel-content',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("No.")]),_c('th',{staticClass:"text-left"},[_vm._v("Section")]),_c('th',{staticClass:"text-left"},[_vm._v("Question")]),_c('th',{staticClass:"text-left"},[_vm._v("Answer")]),_c('th',{staticClass:"text-left"},[_vm._v("Answered By")])])]),_c('tbody',_vm._l((_vm.selectedSubmissionSummary.answers[
                    key
                  ]),function(item,index1){return _c('tr',{key:index1},[_c('td',[_vm._v(_vm._s(index1 + 1))]),_c('td',[_vm._v(_vm._s(item.section))]),_c('td',[_vm._v(_vm._s(item.question))]),_c('td',[_vm._v(_vm._s(item.answer))]),_c('td',[_vm._v(_vm._s(item.answered_by))])])}),0)])],1)],1)}),1)],1):_c('div',[_c('p',{staticClass:"text-center"},[_vm._v(" Summary unavailable. Please select some answers and try again. ")])]),(_vm.summaryBound && _vm.summaryImagesLength)?_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"title"},[_vm._v("Photos of Vehicle")]),_c('v-row',_vm._l((_vm.attachments),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"3"}},[_c('v-img',{attrs:{"contain":"","height":"300px","color":"grey","src":item.image_url,"alt":"Vehicle Thumbnail"},on:{"click":function($event){return _vm.openShowImageDialog(item)}}})],1)}),1)],1):_vm._e(),(_vm.isSubmissionSubmitted === true)?_c('div',{staticClass:"notification is-primary mt-6"},[_c('strong',{staticStyle:{"font-size":"20px","font-family":"Arial"}},[(!_vm.permissions.app_permissions.submissions.approve)?_c('div',[_vm._v(" Form is already completed. ")]):(
            _vm.permissions.app_permissions.submissions.approve &&
              _vm.selectedSubmission.submission.status.toLowerCase() ===
                'approved'
          )?_c('div',[_vm._v(" Form is already approved. ")]):(
            _vm.permissions.app_permissions.submissions.reject &&
              _vm.selectedSubmission.submission.status.toLowerCase() ===
                'rejected'
          )?_c('div',[_c('div',[_vm._v("Form is already rejected.")])]):_vm._e()])]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.submitSubmissionModal),callback:function ($$v) {_vm.submitSubmissionModal=$$v},expression:"submitSubmissionModal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Signature Required")]),_c('v-card-text',[_c('div',[_c('option-signature',{attrs:{"formId":_vm.formId,"question":_vm.signatureQuestion || {},"label":_vm.signatureQuestion.title || ''},on:{"signature-generated":_vm.handleSignature,"signature-cleared":_vm.clearSignature}})],1)]),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-3",attrs:{"loading":_vm.buttonProgress,"disabled":this.signature.file === '',"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v("Complete")])],1)],1)],1),_c('v-dialog',{attrs:{"width":600},model:{value:(_vm.showImageDialog),callback:function ($$v) {_vm.showImageDialog=$$v},expression:"showImageDialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.selectedAttachment.side)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showImageDialog = false}}},[_c('v-icon',[_vm._v(" close ")])],1)],1),_c('v-card-text',[_c('v-img',{staticClass:"my-4",attrs:{"src":_vm.selectedAttachment.image_url,"alt":"image"}}),_c('div',{staticClass:"title"},[_vm._v("Notes:")]),_c('div',[_vm._v(_vm._s(_vm.selectedAttachment.notes))])],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }