<template>
  <div>
    <div class="subtitle-2">{{ question.description }}</div>
    <div class="subtitle-2">{{ label }}</div>
    <div>
      <v-textarea
        disabled
        v-if="!canAnswer || formSubmit"
        :label="question.help_text"
        rows="3"
      >
      </v-textarea>
      <unoapp-signature
        v-else
        ref="signaturePad"
        :option="JSON.stringify(option)"
        :full-width="true"
        :auth-token="authToken"
        @unoBlur="submit"
        @signatureCleared="clearSignaturePad"
      >
      </unoapp-signature>
    </div>
  </div>
</template>

<script>
// import { getPreSignedURL, uploadToS3 } from '@/api/fileUpload'
import { mapGetters } from 'vuex'
export default {
  name: 'OptionSignature',

  data() {
    return {
      signature: {
        file: '',
        presignedURL: '',
        url: ''
      }
    }
  },

  components: {},

  props: {
    label: {
      required: true
    },
    question: {},
    formId: {
      default: ''
    },
    formSubmit: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters({
      authToken: 'auth/authToken'
    }),
    canAnswer() {
      if (this.question.hasOwnProperty('can_answer')) {
        return this.question.can_answer
      }
      return true
    },

    option() {
      let option = this.question.options.find(
        option => option.type === 'signature'
      )
      if (option) return option
      return {}
    }
  },

  methods: {
    async submit(value) {
      this.signature.url = value.detail.answer
      const data = {
        question_id: value.detail.question_id,
        option_id: value.detail.option_id,
        answer: value.detail.answer
      }

      await this.$_submitSingleAnswer({
        data,
        isPublicForm: this.$_isPublicForm
      })
      this.$emit('signature-generated', this.signature)
    },

    clearSignaturePad() {
      this.signature.url = ''
      this.$emit('signature-cleared', true)
    }
  }
}
</script>
