<template>
  <v-container fluid class="mt-7 pa-0 form-container">
    <v-row v-if="page.title">
      <v-col cols="12" class="text-center">
        <h2>{{ page.title }}</h2>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" v-if="page.image_url">
      <v-col cols="6">
        <v-img :src="page.image_url" />
      </v-col>
    </v-row>

    <v-row v-if="page.description">
      <v-col cols="12" class="text-center">
        <div>{{ page.description }}</div>
      </v-col>
    </v-row>

    <v-row v-if="page.button_text">
      <v-col cols="12" class="text-center">
        <div>
          <v-btn color="primary">{{ page.button_text }}</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      submission: 'form/selectedSubmission'
    }),

    page() {
      if (this.submission.settings.attributes.hasOwnProperty('thankyou_page')) {
        return this.submission.settings.attributes.thankyou_page
      }
      return {
        title: '',
        description: '',
        button_text: '',
        image_url: ''
      }
    }
  }
}
</script>

<style></style>
